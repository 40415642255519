import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { Form, Input } from '../../components/Form';
import { PresetTimeDummy } from '../../components/PresetTimeDummy/PresetTimeDummy';
import { showToast } from '../../store/slices/components.slice';
import { getVendorProfile, listVendorProducts, getVendorScheduleClose, requestLalamoveQuotation } from '../../store/slices/customer.slice';
// import { getVendorCapacity, getVendorProfile, listVendorProducts, getVendorScheduleClose, requestLalamoveQuotation } from '../../store/slices/customer.slice';

import { submitOrder } from '../../store/slices/order.slice';
import IconLoading from '../../assets/images/circularSpin-loading.svg';
import _ from 'lodash';
import Tooltip from '../../components/Tooltip';
import { Link } from 'react-router-dom';
import LoadingScreen from '../../components/LoadingScreen';
import MerdekaPromoFrame from '../../assets/images/merdeka-promo-frame.png';

const OrderForm = ({ showDistance, canOrder, onSuccess }) => {
  const { id } = useParams();
  const formNode = useRef();
  const dispatch = useDispatch();
  const history = useHistory();
  const { userApi, orderApi } = useSelector((state) => state);

  const [formErrors, setFormErrors] = useState([]);
  const [vendorInfo, setVendorInfo] = useState({});
  const [availableTimes, setAvailableTimes] = useState([]);
  const [vendorProducts, setVendorProducts] = useState([]);
  const [vendorCapacity, setVendorCapacity] = useState({});
  const [loading, setLoading] = useState(false);
  let [allowToAdd, setAllowToAdd] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDateDiff, setSelectedDateDiff] = useState(null);
  // let [totalOrderLoadUsages, setTotalOrderLoadUsages] = useState([]);
  let [totalQuantities, setTotalQuantities] = useState([]);
  let [sumQuantity, setSumQuantity] = useState(0);
  let [totalPrices, setTotalPrices] = useState([]);
  let [totalAmount, setTotalAmount] = useState(0);
  const [tooltipShowDate, setTooltipShowDate] = useState(true);
  const [tooltipShowTime, setTooltipShowTime] = useState(false);
  const [pickedDeliverTime, setPickedDeliverTime] = useState(null);
  const [deliveryPriceTierSelected, setDeliveryPriceTierSelected] = useState(null);
  const [discountQuantity, setDiscountQuantity] = useState({});
  const [selfPickup, setSelfPickup] = useState(false);
  const [allowedToSetDeliverOption, setAllowedToSetDeliverOption] = useState(false);
  const [customerOnlyOption, setCustomerOnlyOption] = useState(null);
  const [orderDisabled, setOrderDisabled] = useState(false);
  const [fetchTimeLoading, setFetchTimeLoading] = useState(false);
  const [scheduleCloseData, setScheduleCloseData] = useState({});
  const [promoCodeValue, setPromoCodeValue] = useState(null);
  const [promoCodeDiscount, setPromoCodeDiscount] = useState(false);
  const [promoCodeDiscountShowDetails, setPromoCodeDiscountShowDetails] = useState(false);
  const [promoCodeDiscountDetail, setPromoCodeDiscountDetail] = useState({});
  const [promoCodeDiscountTotal, setPromoCodeDiscountTotal] = useState(null);
  const [storeAddressSelected, setStoreAddressSelected] = useState({});
  const [recordLalamoveQuotation, setRecordLalamoveQuotation] = useState({
    request: {
      vendorUid: null,
      deliverDate: null,
      deliverTime: null,
    },
    lalamoveQuotation: {},
  });

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const productId = searchParams.get('product_selected');

  const reducer = (accumulator, curr) => accumulator + curr || 0;
  const filterNotSelectedProduct = (arr) => {
    return arr.filter((el) => {
      return el.quantity && parseInt(el.quantity) !== 0;
    });
  };

  useEffect(async () => {
    setLoading(true);
    if (userApi?.lastReducerAction === 'saveDeliverToAddress' && userApi?.deliverToAddress?.location) {
      const promises = [];
      promises.push(handleListVendorProducts());
      promises.push(handleGetVendorProfile());
      const query = {
        id,
        start: moment().format('YYYY-MM'),
        end: moment().endOf('month').add(4, 'month').format('YYYY-MM'),
      };
      promises.push(handleGetScheduleClose(query));
      await Promise.all(promises);

      await getLalamoveQuote(true);
      setLoading(false);
    }
  }, [userApi]);

  useEffect(async () => {
    getLalamoveQuote();
  }, [pickedDeliverTime]);

  const getLalamoveQuote = async (requery = false) => {
    const pickedDeliverDate = formNode.current.getFormData().deliverDate;
    if (!(pickedDeliverDate && pickedDeliverTime)) {
      return;
    }

    const request = {
      vendorUid: id,
      deliverDate: pickedDeliverDate,
      deliverTime: pickedDeliverTime,
    };
    if (
      (vendorInfo?.lalamoveEnable === true &&
        !(recordLalamoveQuotation.request.deliverTime === request.deliverTime && recordLalamoveQuotation.request.deliverDate === request.deliverDate)) ||
      requery === true
    ) {
      await dispatch(requestLalamoveQuotation(request))
        .unwrap()
        .then((result) => {
          const { lalamoveQuotation } = result;
          console.log('result ', result);
          setRecordLalamoveQuotation({
            request: request,
            lalamoveQuotation,
          });
        })
        .catch(({ message }) => {
          dispatch(showToast({ show: true, type: 'error', message: message }));
        });
    }
  };

  const handleListVendorProducts = async () => {
    if (vendorProducts.length === 0) {
      await dispatch(listVendorProducts(id))
        .unwrap()
        .then((result) => {
          const { data } = result;
          let selectedProduct = [];
          let otherProduct = [];
          data.products.forEach((product, index) => {
            if (product.uid == productId) {
              selectedProduct.push(product);
            } else {
              otherProduct.push(product);
            }
          });
          setVendorProducts([...selectedProduct, ...otherProduct]);
        })
        .catch(({ message }) => {
          dispatch(showToast({ show: true, type: 'error', message: message }));
        });
    }
  };

  const handleGetVendorProfile = async (date) => {
    if (userApi?.deliverToAddress?.location) {
      await dispatch(getVendorProfile({ id: id, location: JSON.stringify(userApi?.deliverToAddress?.location) }))
        .unwrap()
        .then((result) => {
          const { data } = result;
          setVendorInfo(data);
          if (data?.deliveryEnable === false) {
            setSelfPickup(true);
          }
          if (!data?.storeAddressSelected?.address1) {
            setStoreAddressSelected({
              city: data?.storeCity,
              state: data?.storeState,
            });
          } else {
            setStoreAddressSelected({
              city: data?.storeAddressSelected?.city,
              state: data?.storeAddressSelected?.state,
            });
          }
          showDistance(data.distance);
          if (data.storeDeliveryPriceEnable && data?.storeDeliveryPriceTier?.length > 0) {
            for (let i = 0; i < data.storeDeliveryPriceTier.length; i++) {
              const tier = data.storeDeliveryPriceTier[i];
              if (data.distance >= tier.begin && data.distance < tier.end) {
                setDeliveryPriceTierSelected({ ...tier, index: i });
                break;
              }
            }
          }

          // Can make an order
          if ((data.distance < data.availabilityRadiusKm && data.deliveryEnable) || data.selfPickupEnable) {
            canOrder(true);
            setOrderDisabled(true);
          } else {
            canOrder(false);
            setOrderDisabled(false);
          }
          if (data.selfPickupEnable && (!data.deliveryEnable || data.distance > data.availabilityRadiusKm)) {
            setSelfPickup(true);
            setCustomerOnlyOption('Self Pickup option only');
            setAllowedToSetDeliverOption(false);
          } else if (data.deliveryEnable && !data.selfPickupEnable) {
            setCustomerOnlyOption('Delivery option only');
            setAllowedToSetDeliverOption(false);
          } else {
            setAllowedToSetDeliverOption(true);
          }
        })
        .catch(({ message }) => {
          dispatch(showToast({ show: true, type: 'error', message: message }));
        });
    } else {
      history.push('/addresses');
    }
  };

  const handleGetScheduleClose = async (query) => {
    await dispatch(getVendorScheduleClose(query))
      .unwrap()
      .then((result) => {
        const { data } = result;
        setScheduleCloseData(data.scheduleCloseData);
      })
      .catch(({ error, message }) => {
        dispatch(showToast({ show: true, type: 'error', message: error || message }));
      });
  };

  const handleGetVendorCapacity = async (date) => {
    setFetchTimeLoading(true);
    setSelectedDate(date);
    setAvailableTimes([]);

    handleResetField({ products: [], deliverDate: date });
    setTooltipShowDate(false);
    setTooltipShowTime(true);

    let allowProductToAdd = [];

    vendorProducts.forEach((product, index) => {
      allowProductToAdd[index] = true;
    });

    setAllowToAdd(allowProductToAdd);

    // Get date difference between selected date and today (this include time different as well)
    let day = moment(new Date(date)).format('dddd').toLowerCase();
    let dateSelected = moment(new Date(date));
    let today = moment();
    setSelectedDateDiff(dateSelected.diff(today, 'days'));

    // Set available time based on selected date
    setAvailableTimes(vendorInfo.storeOperationSchedules[day]);
    setFetchTimeLoading(false);

    // await dispatch(getVendorCapacity({ id: id, date: date }))
    //   .unwrap()
    //   .then((result) => {
    //     // const { capacity } = result;
    //     // setVendorCapacity(capacity);
    //     let day = moment(new Date(date)).format('dddd').toLowerCase();

    //     // Get date difference between selected date and today (this include time different as well)
    //     let dateSelected = moment(new Date(date));
    //     let today = moment();
    //     setSelectedDateDiff(dateSelected.diff(today, 'days'));

    //     // Set available time based on selected date
    //     setAvailableTimes(vendorInfo.storeOperationSchedules[day]);
    //     setFetchTimeLoading(false);
    //   })
    //   .catch(({ message }) => {
    //     dispatch(showToast({ show: true, type: 'error', message: message }));
    //     setFetchTimeLoading(false);
    //   });
  };

  //   await dispatch(getVendorCapacity({ id: id, date: date }))
  //     .unwrap()
  //     .then((result) => {
  //       const { capacity } = result;
  //       setVendorCapacity(capacity);
  //       let day = moment(new Date(date)).format('dddd').toLowerCase();

  //       // Get date difference between selected date and today (this include time different as well)
  //       let dateSelected = moment(new Date(date));
  //       let today = moment();
  //       setSelectedDateDiff(dateSelected.diff(today, 'days'));

  //       // Set available time based on selected date
  //       setAvailableTimes(vendorInfo.storeOperationSchedules[day]);
  //       setFetchTimeLoading(false);
  //     })
  //     .catch(({ message }) => {
  //       dispatch(showToast({ show: true, type: 'error', message: message }));
  //       setFetchTimeLoading(false);
  //     });
  // };

  const handleQuantitySum = (qty, price, index) => {
    // totalOrderLoadUsages[index] = qty * loadUsage;
    // setTotalOrderLoadUsages(totalOrderLoadUsages);
    totalQuantities[index] = qty;
    setTotalQuantities(totalQuantities);
    const currentTotalQuantities = totalQuantities.reduce(reducer);
    setSumQuantity(currentTotalQuantities);
    totalPrices[index] = qty * price;
    setTotalPrices(totalPrices);
    const currentTotalAmount = totalPrices.reduce(reducer);
    setTotalAmount(currentTotalAmount);

    if (vendorInfo?.storeDiscountQuantityEnable && vendorInfo?.storeDiscountQuantityTier?.length > 0) {
      setDiscountQuantity({});
      for (let i = 0; i < vendorInfo.storeDiscountQuantityTier.length; i++) {
        const tier = vendorInfo.storeDiscountQuantityTier[i];
        if (currentTotalQuantities >= tier.begin && currentTotalQuantities <= tier.end) {
          setDiscountQuantity({ ...tier, index: i });
          break;
        }
        if (vendorInfo.storeDiscountQuantityTier.length === i + 1 && currentTotalQuantities >= tier.begin) {
          setDiscountQuantity({ ...tier, index: i });
          break;
        }
      }
    }

    // Calculate Promo Discount based on promoCodeDiscount
    const newDetail = promoCodeDiscountDetail;
    const discountPrice = (vendorProducts[index].price * qty * 0.05).toFixed(2);
    newDetail[vendorProducts[index].uid] = {
      name: vendorProducts[index].name,
      discount: discountPrice > 5.0 ? 5.0 : discountPrice,
    };

    setPromoCodeDiscountDetail(newDetail);
    let totalDiscount = 0.0;
    for (const [key, value] of Object.entries(newDetail)) {
      console.log(`${key}: ${value}`);
      totalDiscount = totalDiscount + Number(value.discount);
    }

    setPromoCodeDiscountTotal(totalDiscount.toFixed(2));

    // // Check if vendor current load usage + user current load usage + another product quantity load usage is more than vendor total load capacity
    // vendorProducts.forEach((product, index) => {
    //   if (vendorCapacity.totalLoadUsage + totalOrderLoadUsages.reduce(reducer) + product.loadUsage > vendorCapacity.totalLoadCapacity) {
    //     allowToAdd[index] = false;
    //     setAllowToAdd(allowToAdd);
    //   } else {
    //     allowToAdd[index] = true;
    //     setAllowToAdd(allowToAdd);
    //   }
    // });
  };

  const handleSubmit = (data) => {
    data.deliverDate = moment(data.deliverDate).format('YYYY-MM-DD');
    data.deliverTime = data.deliverTime ?? pickedDeliverTime;
    if (vendorInfo?.multiLocation === true && vendorInfo?.storeAddressSelected?.address1) {
      data.storeAddressSelected = vendorInfo.storeAddressSelected;
    }

    if (!selfPickup) {
      if (!vendorInfo?.lalamoveEnable && deliveryPriceTierSelected?.price) {
        data.deliveryPriceTier = deliveryPriceTierSelected;
      } else if (vendorInfo?.lalamoveEnable && recordLalamoveQuotation?.lalamoveQuotation?.markup?.totalPrice) {
        data.lalamoveQuotation = recordLalamoveQuotation?.lalamoveQuotation;
      }
    }

    if (promoCodeDiscount) {
      data.customerDiscount = true;
    }

    data.selfPickup = selfPickup;
    let products = filterNotSelectedProduct(data.products);

    dispatch(submitOrder(Object.assign(data, { vendorUid: id, address: userApi.deliverToAddress, products: products })))
      .unwrap()
      .then((result) => {
        onSuccess(result.order);
        dispatch(showToast({ show: true, type: 'success', message: 'Order checkout. Proceed to payment process.' }));
      })
      .catch(({ message, validate }) => {
        handleGetVendorCapacity(selectedDate);
        dispatch(showToast({ show: true, type: 'error', message: message }));
      });
  };

  const handleResetField = (fields) => {
    formNode.current.resetFields(fields);
    setAllowToAdd([]);
    // setTotalOrderLoadUsages([]);
    setTotalQuantities([]);
    setTotalPrices([]);
    setTotalAmount(0);
  };

  const handleTooltipTime = () => {
    setTooltipShowTime(false);
  };

  const disabledDate = (current) => {
    return !(moment().diff(current, 'days') < 0) || !(moment().add(3, 'month').diff(current, 'days') > 0) || scheduleCloseData[current.format('YYYY-MM-DD')];
  };

  const dateRender = (current) => {
    const style = {};
    if (scheduleCloseData[current.format('YYYY-MM-DD')]) {
      style.background = '#ffcccc';
    }
    return (
      <div className="ant-picker-cell-inner" style={style}>
        {current.date()}
      </div>
    );
  };

  return (
    <>
      <LoadingScreen show={loading} />
      {!orderDisabled ? (
        <div className="grid grid-cols-4 gap-4 sm:gap-4 p-6">
          <div className="col-span-4 sm:col-span-2">
            <span className="font-medium text-sm">Store:</span>
            <Link to={`/vendor/${vendorInfo?.username || vendorInfo?.uid}`}>
              <p className="text-sm text-primary-600 hover:text-amber-600 hover:font-semibold">{vendorInfo?.storeName}</p>
            </Link>
          </div>
          <div className="col-span-2 sm:col-span-1">
            <span className="font-medium text-sm">Based on:</span>
            <p className="text-sm text-gray-500">
              {storeAddressSelected.city}, {storeAddressSelected.state}
            </p>
          </div>
        </div>
      ) : (
        <Form onSubmit={handleSubmit} setRef={formNode} setErrors={formErrors}>
          <div className="grid grid-cols-4 gap-4 sm:gap-4 p-6">
            <div className="col-span-4 sm:col-span-2">
              <span className="font-medium text-sm">Store:</span>
              <Link to={`/vendor/${vendorInfo?.username || vendorInfo?.uid}`}>
                <p className="text-sm text-primary-600 hover:text-amber-600 hover:font-semibold">{vendorInfo?.storeName}</p>
              </Link>
            </div>
            <div className="col-span-2 sm:col-span-1">
              <span className="font-medium text-sm">Based on:</span>
              <p className="text-sm text-gray-500">
                {storeAddressSelected.city}, {storeAddressSelected.state}
              </p>
            </div>
          </div>
          <div className="border-t border-gray-200">
            <div className="grid grid-cols-1 gap-1 sm:grid-cols-2 sm:gap-2 p-6">
              <div className="grid grid-cols-2 gap-2 sm:gap-2 space-x-2 rounded-xl bg-white border border-gray-200  p-2 ">
                <div>
                  <input
                    type="radio"
                    name="option"
                    id="1"
                    className="peer hidden"
                    disabled={!allowedToSetDeliverOption && selfPickup}
                    checked={!selfPickup}
                    onChange={(e) => setSelfPickup(!selfPickup)}
                  />
                  <label
                    for="1"
                    className={`block  select-none rounded-xl p-2 text-center peer-checked:bg-primary-500 peer-checked:font-bold peer-checked:text-white ${
                      !allowedToSetDeliverOption && selfPickup ? 'bg-gray-100 cursor-not-allowed' : 'cursor-pointer'
                    } `}
                  >
                    Delivery
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    name="option"
                    id="2"
                    className="peer hidden"
                    disabled={!allowedToSetDeliverOption && !selfPickup}
                    checked={selfPickup}
                    onChange={(e) => setSelfPickup(!selfPickup)}
                  />
                  <label
                    for="2"
                    className={`block  select-none rounded-xl p-2 text-center peer-checked:bg-primary-500 peer-checked:font-bold peer-checked:text-white ${
                      !allowedToSetDeliverOption && !selfPickup ? 'bg-gray-100 cursor-not-allowed' : 'cursor-pointer'
                    } `}
                  >
                    Self Pickup
                  </label>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-4 gap-4 sm:gap-4 p-6">
              <div className="col-span-4 sm:col-span-2">
                {customerOnlyOption && (
                  <span className="ml-auto mt-1 px-2 inline-flex text-xs leading-5 font-semibold rounded-md bg-primary-100 text-primary-600 uppercase">{customerOnlyOption}</span>
                )}
              </div>
            </div>
          </div>

          <div className="border-t border-gray-200 pt-4 ">
            <div className="px-6">
              <div className="mt-2 grid grid-cols-6 gap-6">
                <div className="col-span-6">
                  {tooltipShowDate && (
                    <Tooltip from="top" bgColor="bg-amber-500/95 text-white" chevronColor="text-amber-500">
                      Please select date
                    </Tooltip>
                  )}
                  <Input.DatePickerV2
                    name="deliverDate"
                    label={selfPickup ? 'Pickup date' : 'Delivery date'}
                    retrieveValue={(data) => handleGetVendorCapacity(data)}
                    disabled={loading || orderApi.loading}
                    rules={[{ required: true, message: 'This field is required' }]}
                    placeholder={'yyyy-mm-dd'}
                    disabledDate={disabledDate}
                    dateRender={dateRender}
                  />
                </div>
                {fetchTimeLoading ? (
                  <div class="col-span-6 flex flex-col justify-center items-center">
                    <object className="w-20 h-20" type="image/svg+xml" data={IconLoading}>
                      svg-animation
                    </object>
                  </div>
                ) : (
                  <>
                    {availableTimes.length > 0 ? (
                      <div className="col-span-6">
                        {tooltipShowTime && (
                          <Tooltip from="top" bgColor="bg-amber-500/95 text-white" chevronColor="text-amber-500">
                            Please select time
                          </Tooltip>
                        )}

                        <Input.PresetTimePicker
                          name="deliverTime"
                          label={selfPickup ? 'Pickup time' : 'Delivery time'}
                          singleSelect
                          times={availableTimes}
                          retrieveValue={(data) => {
                            setPickedDeliverTime(data.target.value);
                            handleTooltipTime();
                          }}
                          disabled={loading || orderApi.loading}
                          rules={[{ required: true, message: 'This field is required' }]}
                        />
                      </div>
                    ) : (
                      <div className="col-span-6">
                        {tooltipShowTime && loading == false && orderApi.loading == false && (
                          <Tooltip from="top" bgColor="bg-amber-500/95 text-white" chevronColor="text-amber-500">
                            This current date is no time available
                          </Tooltip>
                        )}
                        <PresetTimeDummy selfPickup={selfPickup} />
                      </div>
                    )}
                  </>
                )}
                {vendorInfo?.storeDeliveryPriceEnable && vendorInfo?.storeDiscountQuantityTier && !promoCodeDiscount && (
                  <div className="col-span-6">
                    <div class="block p-6 bg-primary-100 border border-primary-300 rounded-lg shadow">
                      {vendorInfo.storeDiscountQuantityTier.map((item, index) => (
                        <>
                          {index + 1 === vendorInfo.storeDiscountQuantityTier.length ? (
                            <p className="font-bold">
                              * Minimum quantity {item.begin} get discount {item.discount}%{' '}
                            </p>
                          ) : (
                            <p className="font-bold">
                              * Minimum quantity {item.begin} to up {item.end} get discount {item.discount}%{' '}
                            </p>
                          )}
                        </>
                      ))}
                    </div>
                  </div>
                )}

                <div className="col-span-6">
                  <div class="block p-3 bg-primary-100 border border-primary-300 rounded-lg shadow">
                    {promoCodeDiscount && (
                      <div class="max-w-sm w-full bg-white border rounded-lg shadow-md p-4">
                        <div class="flex justify-between items-center">
                          <p class="text-gray-700"> Promo: {vendorInfo.customerDiscount?.message}.</p>
                          <button
                            class="text-gray-500 hover:text-gray-700 focus:outline-none"
                            onClick={() => {
                              setPromoCodeDiscount(false);
                            }}
                          >
                            <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
                            </svg>
                          </button>
                        </div>
                      </div>
                    )}
                    {!promoCodeDiscount && (
                      <div class="flex space-x-2">
                        <input
                          type="text"
                          class="w-8/12 py-1 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500"
                          placeholder="Enter promo code"
                          onChange={(event) => {
                            setPromoCodeValue(event.target.value);
                          }}
                        />
                        <button
                          type="button"
                          class="w-4/12 py-1 text-white bg-primary-500 rounded-md hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-primary-500"
                          onClick={() => {
                            if (promoCodeValue.toUpperCase().trim() === vendorInfo.customerDiscount?.code) {
                              setPromoCodeDiscount(true);
                              dispatch(showToast({ show: true, type: 'success', message: `Applied Promo: ${vendorInfo.customerDiscount?.message}` }));
                            } else {
                              dispatch(showToast({ show: true, type: 'error', message: `Invalid promo ${promoCodeValue.toUpperCase()}` }));
                            }
                          }}
                        >
                          apply
                        </button>
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-span-6 border-t pt-4">
                  <div className="mt-1 flow-root">
                    {/* web view */}
                    <ul role="list" className="-my-6 divide-y divide-gray-200 sm:block hidden">
                      {vendorProducts.map((product, index) => (
                        <li key={index} className="py-6 flex flex-col">
                          <div className="flex">
                            <div className="flex-shrink-0 w-24 h-24 border border-gray-200 rounded-md overflow-hidden">
                              {product?.merdeka_promo_frame ? (
                                <div class="w-full h-full object-center object-cover">
                                  <img src={product?.picture?.path?.s} alt={product?.uid} className="h-full" />
                                  <img src={MerdekaPromoFrame} alt={'Merdeka Promo'} className="relative bottom-full h-full w-full" />
                                </div>
                              ) : (
                                <img src={product?.picture?.path?.s} alt={product?.uid} className="w-full h-full object-center object-cover" />
                              )}
                            </div>

                            <div className="ml-4 flex-1 flex justify-between">
                              <div className="flex flex-col text-base font-medium text-gray-900">
                                <h3>
                                  <Input.Text className="sr-only" name={`products[${index}].productUid`} value={product?.uid} />
                                  <p>{product?.name}</p>
                                </h3>
                                <div className="flex flex-row">
                                  <p className="text-gray-600">RM {product?.price?.toFixed(2)}</p>
                                  {product?.display_price_dummy && (
                                    <p className="text-sm line-through font-light text-orange-600 ml-2 relative top-0.5">RM {product?.display_price_dummy?.toFixed(2)}</p>
                                  )}
                                </div>
                                {/* <div className={`text-xs ${!allowToAdd[index] ? 'text-red-500' : 'text-gray-400'}`}>Capacity Usage {product?.loadUsage}</div> */}
                                {!product?.isAvailable && <p className="text-sm font-normal text-red-500">* Product not available</p>}
                                {/* {selectedDateDiff < product?.leadTime && (
                                  <p className="text-sm font-normal text-red-500">
                                    * Product requires {product?.leadTime > 1 ? `${product?.leadTime} days` : `${product?.leadTime} day`} to order
                                  </p>
                                )} */}
                                {/* {vendorCapacity.totalLoadUsage + product?.loadUsage >= vendorCapacity.totalLoadCapacity && (
                                  <p className="text-sm font-normal text-red-500">* Product load usage will exceed total load capacity if added</p>
                                )} */}
                              </div>
                              <div>
                                <Input.Quantity
                                  name={`products[${index}].quantity`}
                                  retrieveValue={({ value }) => handleQuantitySum(value, product?.price, index)}
                                  disabled={
                                    loading ||
                                    !product?.isAvailable ||
                                    !selectedDateDiff ||
                                    // (selectedDateDiff && selectedDateDiff < product?.leadTime) ||
                                    // vendorCapacity.totalLoadUsage + product?.loadUsage + (totalOrderLoadUsages.length > 0 ? totalOrderLoadUsages.reduce(reducer) : 0) >
                                    //   vendorCapacity.totalLoadCapacity ||
                                    orderApi.loading
                                  }
                                  canAddMore={allowToAdd[index]}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="mt-4">
                            <Input.Textarea
                              name={`products[${index}].remark`}
                              placeholder="Enter order remark for this product"
                              fieldRows={2}
                              disabled={
                                loading ||
                                !product?.isAvailable ||
                                !selectedDateDiff ||
                                // (selectedDateDiff && selectedDateDiff < product?.leadTime) ||
                                orderApi.loading
                              }
                            />
                          </div>
                        </li>
                      ))}
                      <div className="p-6" />
                    </ul>

                    {/* mobile View */}
                    <ul role="list" className="-my-6 divide-y divide-gray-200 sm:hidden block">
                      {vendorProducts.map((product, index) => (
                        <li key={index} className="py-6 flex flex-col">
                          <div className="flex">
                            <div className="flex-shrink-0 w-24 h-24 border border-gray-200 rounded-md overflow-hidden">
                              <img
                                src={product?.picture?.path?.s}
                                alt="Front of satchel with blue canvas body, black straps and handle, drawstring top, and front zipper pouch."
                                className="w-full h-full object-center object-cover"
                              />
                            </div>

                            <div className="ml-4 flex-1 flex  place-items-center flex-row-reverse">
                              <div>
                                <Input.Quantity
                                  name={`products[${index}].quantity`}
                                  retrieveValue={({ value }) => handleQuantitySum(value, product?.price, index)}
                                  // retrieveValue={({ value }) => handleQuantitySum(value, product?.price, product?.loadUsage, index)}
                                  disabled={
                                    loading ||
                                    !product?.isAvailable ||
                                    !selectedDateDiff ||
                                    // (selectedDateDiff && selectedDateDiff < product?.leadTime) ||
                                    // vendorCapacity.totalLoadUsage + product?.loadUsage + (totalOrderLoadUsages.length > 0 ? totalOrderLoadUsages.reduce(reducer) : 0) >
                                    //   vendorCapacity.totalLoadCapacity ||
                                    orderApi.loading
                                  }
                                  canAddMore={allowToAdd[index]}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-col text-base font-medium text-gray-900 py-3">
                            <div className="flex justify-between">
                              <h3>
                                <Input.Text className="sr-only" name={`products[${index}].productUid`} value={product?.uid} />
                                <p>{product?.name}</p>
                              </h3>
                              <p className="text-gray-600">RM {product?.price.toFixed(2)}</p>
                            </div>
                            {/* <div className={`text-xs ${!allowToAdd[index] ? 'text-red-500' : 'text-gray-400'}`}>Capacity Usage {product?.loadUsage}</div> */}
                            <div className="flex justify-between">
                              {!product?.isAvailable && <p className="text-sm font-normal text-red-500">* Product not available</p>}
                              {/* {selectedDateDiff < product?.leadTime && (
                                <p className="text-sm font-normal text-red-500">
                                  * Product requires {product?.leadTime > 1 ? `${product?.leadTime} days` : `${product?.leadTime} day`} to order
                                </p>
                              )} */}
                              {/* {vendorCapacity.totalLoadUsage + product?.loadUsage >= vendorCapacity.totalLoadCapacity && (
                                <p className="text-sm font-normal text-red-500">* Product load usage will exceed total load capacity if added</p>
                              )} */}
                            </div>
                          </div>
                          <div className="mt-2">
                            <Input.Textarea
                              name={`products[${index}].remark`}
                              placeholder="Enter order remark for this product"
                              fieldRows={2}
                              disabled={
                                loading ||
                                !product?.isAvailable ||
                                !selectedDateDiff ||
                                // (selectedDateDiff && selectedDateDiff < product?.leadTime) ||
                                orderApi.loading
                              }
                            />
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="p-4" />
          <div className="z-10 fixed bottom-0 bg-white inset-x-0 px-6 py-4  items-center justify-between border-t">
            {(deliveryPriceTierSelected || vendorInfo?.lalamoveEnable) && !selfPickup && (
              <div className="flex flex-col text-base font-medium text-gray-900">
                <div className="flex justify-between">
                  <h3>
                    <p className="mr-4">
                      <b>Delivery Charge</b> RM{' '}
                      {vendorInfo?.lalamoveEnable === true ? (
                        <>
                          {recordLalamoveQuotation?.lalamoveQuotation?.markup?.totalPrice ? (
                            recordLalamoveQuotation?.lalamoveQuotation?.markup?.totalPrice.toFixed(2)
                          ) : (
                            <a className="text-gray-500">Pending... </a>
                          )}
                          {!pickedDeliverTime && <a className="text-gray-500">Please select delivery date and time</a>}
                        </>
                      ) : (
                        <>{deliveryPriceTierSelected.price.toFixed(2)}</>
                      )}
                    </p>
                  </h3>
                </div>
              </div>
            )}

            {promoCodeDiscount && promoCodeDiscountTotal && (
              <div className="flex flex-col text-base font-medium text-gray-900 p-1 bg-primary-100 border border-primary-300 rounded-lg shadow">
                <div className="flex justify-between items-center">
                  <h3>
                    <p>
                      <b>Discount RM {promoCodeDiscountTotal}</b>
                    </p>
                  </h3>
                  {!promoCodeDiscountShowDetails && (
                    <button
                      className="bg-primary-100 text-primary-500 font-bold py-1 px-2 border-primary-500 rounded"
                      type="button"
                      onClick={() => {
                        setPromoCodeDiscountShowDetails(true);
                      }}
                    >
                      Show details
                    </button>
                  )}
                  {promoCodeDiscountShowDetails && (
                    <button
                      className="bg-primary-100 text-primary-500 font-bold py-1 px-2 border-primary-500 rounded"
                      type="button"
                      onClick={() => {
                        setPromoCodeDiscountShowDetails(false);
                      }}
                    >
                      Hide details
                    </button>
                  )}
                </div>
                {promoCodeDiscountDetail &&
                  promoCodeDiscountShowDetails &&
                  Object.keys(promoCodeDiscountDetail).map((data, key) => {
                    return (
                      <p className="text-xs" key={key}>
                        {promoCodeDiscountDetail[data]?.name} - RM{promoCodeDiscountDetail[data]?.discount}
                      </p>
                    );
                  })}
              </div>
            )}

            {vendorInfo.storeDiscountQuantityEnable && discountQuantity?.discount && !promoCodeDiscount && (
              <div className="flex flex-col text-base font-medium text-gray-900">
                <div className="flex justify-between">
                  <h3>
                    <p>
                      <b>Discount {discountQuantity.discount}%</b> RM {((totalAmount * discountQuantity.discount) / 100).toFixed(2)}
                    </p>
                  </h3>
                </div>
              </div>
            )}
            {totalAmount > 0 ? (
              <div className="col-span-6 mb-3">
                <Input.Textarea
                  name="orderRemark"
                  label="Special instructions"
                  placeholder="Enter your special instructions"
                  fieldRows={2}
                  disabled={loading || !selectedDateDiff || orderApi.loading}
                />
              </div>
            ) : null}
            <div className="flex-1 flex items-center justify-between">
              <div>
                <div className="flex items-center">
                  <div className="flex items-baseline">
                    <p className="text-sm font-medium text-gray-700">Amount</p>
                    {/* {vendorCapacity?.totalLoadCapacity && (
                      <div className={`px-2 text-xs ${allowToAdd.every((element) => element === false) ? 'text-red-500' : 'text-gray-400'}`}>
                        (Store Capacity Balance&nbsp;
                        {totalOrderLoadUsages.reduce((a, b) => a + b, 0)}/{vendorCapacity?.totalLoadCapacity - vendorCapacity?.totalLoadUsage})
                      </div>
                    )} */}
                  </div>
                  {/* <span className="hidden flex-none uppercase bg-primary-200 text-primary-600 text-xs tracking-wide font-semibold px-1 py-1 rounded-md ml-2">
                Discounted
              </span> */}
                </div>
                <h2 className="text-2xl font-bold text-gray-900">
                  <span>
                    RM{' '}
                    {vendorInfo?.lalamoveEnable === true ? (
                      <>
                        {totalAmount > 0
                          ? (
                              totalAmount +
                              (!selfPickup && recordLalamoveQuotation?.lalamoveQuotation?.markup?.totalPrice ? recordLalamoveQuotation?.lalamoveQuotation?.markup?.totalPrice : 0) -
                              (!promoCodeDiscount && discountQuantity?.discount ? (totalAmount * discountQuantity.discount) / 100 : 0) -
                              (promoCodeDiscount ? Number(promoCodeDiscountTotal) : 0)
                            ).toFixed(2)
                          : (0).toFixed(2)}
                      </>
                    ) : (
                      <>
                        {totalAmount > 0
                          ? (
                              totalAmount +
                              (!selfPickup && deliveryPriceTierSelected?.price ? deliveryPriceTierSelected?.price : 0) -
                              (!promoCodeDiscount && discountQuantity?.discount ? (totalAmount * discountQuantity.discount) / 100 : 0) -
                              (promoCodeDiscount ? Number(promoCodeDiscountTotal) : 0)
                            ).toFixed(2)
                          : (0).toFixed(2)}
                      </>
                    )}
                  </span>
                </h2>
              </div>
              <div>
                <div className="flex lg:ml-4">
                  <span className="sm:ml-3">
                    <button
                      type="submit"
                      disabled={loading || !selectedDateDiff || !pickedDeliverTime || !(totalAmount > 0) || orderApi.loading}
                      className="disabled:bg-gray-300 disabled:opacity-50 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                    >
                      {orderApi.loading ? (
                        <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      ) : (
                        'Order now'
                      )}
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </>
  );
};

export default OrderForm;
