import { Fragment, useEffect, useRef, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { getStripeAccountLink, getVendor, updateVendor } from '../../../store/slices/vendor.slice';
import { showToast } from '../../../store/slices/components.slice';

import { chunkingOperationHours, classNames, getAddressGeocode, ServiceChargePercentage } from '../../../utils';
import Image from '../../../components/Image';
import { getPrivateFile } from '../../../store/slices/file.slice';
import { Form, Input } from '../../../components/Form';
import Tooltip from '../../../components/Tooltip';
import ShareButton from '../../../components/ShareButton';

import { useHistory, useParams } from 'react-router';

const StoreProfile = ({ vendorData }) => {
  const dispatch = useDispatch();
  const { vendorApi, fileApi } = useSelector((state) => state);

  const [profile, setProfile] = useState(null);
  const [formData, setFormData] = useState(profile);
  const [formErrors, setFormErrors] = useState([]);
  const [selectedDay, setSelectedDay] = useState('sunday');
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [deliveryPriceTierEnable, setDeliveryPriceTierEnable] = useState(false);
  const [discountQuantityTierEnable, setDiscountQuantityTierEnable] = useState(false);
  const [latestAvailabilityRadiusKm, setLatestAvailabilityRadiusKm] = useState(0);

  const [selfPickupEnable, setSelfPickupEnable] = useState(false);
  const [deliveryEnable, setDeliveryEnable] = useState(false);
  const [customerOptionWarnig, setCustomerOptionWarnig] = useState(false);

  const history = useHistory();

  useEffect(() => {
    let profileData = vendorData?.data;
    if (!(typeof profileData?.storeDeliveryPriceEnable === 'boolean' && Array.isArray(profileData?.storeDeliveryPriceTier))) {
      const storeDeliveryPriceTier = [
        {
          begin: 0,
          end: 5,
          price: 0,
        },
      ];
      const storeDeliveryPriceEnable = false;
      profileData = { ...profileData, ...{ storeDeliveryPriceTier, storeDeliveryPriceEnable } };
    }
    if (!(typeof profileData?.storeDiscountQuantityEnable === 'boolean' && Array.isArray(profileData?.storeDiscountQuantityTier))) {
      const storeDiscountQuantityTier = [
        {
          begin: 10,
          end: 19,
          discount: 1,
        },
      ];
      const storeDiscountQuantityEnable = false;
      profileData = { ...profileData, ...{ storeDiscountQuantityTier, storeDiscountQuantityEnable } };
    }
    setProfile(profileData);
    setSelfPickupEnable(profileData.selfPickupEnable);
    setDeliveryEnable(profileData.deliveryEnable);

    setFormData(profileData);
    setDeliveryPriceTierEnable(profileData?.storeDeliveryPriceEnable);
    setDiscountQuantityTierEnable(profileData?.storeDiscountQuantityEnable);
    setLatestAvailabilityRadiusKm(profileData?.availabilityRadiusKm);
  }, [vendorData]);

  useEffect(() => {
    setTriggerUpdate(!triggerUpdate);
  }, [formData]);

  const formNode = useRef();
  const mapRef = useRef();

  const days = [
    { name: 'Sun', value: 'sunday' },
    { name: 'Mon', value: 'monday' },
    { name: 'Tue', value: 'tuesday' },
    { name: 'Wed', value: 'wednesday' },
    { name: 'Thu', value: 'thursday' },
    { name: 'Fri', value: 'friday' },
    { name: 'Sat', value: 'saturday' },
  ];

  const updateFormData = (data) => {
    setFormData({ ...formData, ...formNode.current.getFormData(), ...data });
  };

  const handleSubmit = (data) => {
    data.action = 'SAVE';
    delete data.storeLocationAddressHiddenInput;

    const storeDeliveryPriceTier = JSON.parse(JSON.stringify(formData.storeDeliveryPriceTier));
    if (storeDeliveryPriceTier.length > 0) {
      data.storeDeliveryPriceTier = storeDeliveryPriceTier;
    }

    if (deliveryPriceTierEnable && latestAvailabilityRadiusKm > formData?.storeDeliveryPriceTier[formData?.storeDeliveryPriceTier.length - 1].end) {
      dispatch(showToast({ show: true, type: 'warning', message: 'Please check Delivery price tier section' }));
      const element = document.getElementById('section-deliveryPriceTier-warning');
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      return;
    }

    const storeDiscountQuantityTier = JSON.parse(JSON.stringify(formData.storeDiscountQuantityTier));
    if (discountQuantityTierEnable && storeDiscountQuantityTier.length > 0) {
      data.storeDiscountQuantityTier = storeDiscountQuantityTier;
    }

    const storeDiscountQuantityTierError = storeDiscountQuantityTier?.find((item) => item.error);
    if (discountQuantityTierEnable && storeDiscountQuantityTierError) {
      dispatch(showToast({ show: true, type: 'warning', message: 'Please check Discount Quantity Tier section' }));
      const element = document.getElementById('section-discountQuantityTier-warning');
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      return;
    }

    if (formData?.selfPickupEnable === false && formData?.deliveryEnable === false) {
      setCustomerOptionWarnig(true);
      dispatch(showToast({ show: true, type: 'warning', message: 'Please check Customer option section' }));
      const element = document.getElementById('section-customerOption-warning');
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      return;
    }

    dispatch(updateVendor(data))
      .unwrap()
      .then((result) => {
        const { data } = result;
        setFormData(data);
        setProfile(data);
        setDeliveryPriceTierEnable(data?.storeDeliveryPriceEnable);
        setDiscountQuantityTierEnable(data?.storeDiscountQuantityEnable);
        setLatestAvailabilityRadiusKm(data?.availabilityRadiusKm);
        dispatch(showToast({ show: true, type: 'success', message: `Your store profile has been successfully updated!` }));
        history.push('/store/profile');
      })
      .catch(({ message, validate }) => {
        dispatch(showToast({ show: true, type: 'error', message: message, errors: validate?.details }));
        setFormErrors(validate?.details);
        setTimeout(() => {
          const element = document.getElementById('rule-error');
          element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }, 100);
      });
  };

  const handleStoreAddress = async () => {
    let currentFormData = formData;

    let addressObj = {
      storeAddress1: currentFormData['storeAddress1'],
      storeAddress2: currentFormData['storeAddress2'] || '',
      storeCity: currentFormData['storeCity'],
    };

    if (mapRef) {
      const geocoder = new mapRef.current.map.maps_.Geocoder();
      let { state, postalCode, coordinate } = await getAddressGeocode(geocoder, addressObj);
      updateFormData({
        storeState: state || '',
        storePostalCode: postalCode || '',
        storeLocationLatLong: coordinate || {},
      });
    }
  };

  const handleStoreDeliveryPriceTierChange = (event) => {
    let storeDeliveryPriceTier = JSON.parse(JSON.stringify(formData.storeDeliveryPriceTier));
    switch (event.target.name) {
      case 'autoGenerate':
        storeDeliveryPriceTier = [];
        const lastDistance = Number(latestAvailabilityRadiusKm) + 1;
        const nextDistance = 5;
        const nextPrice = 3;
        let currentDistance = 0;
        let currentPrice = 2;
        while (currentDistance < lastDistance) {
          storeDeliveryPriceTier.push({
            begin: currentDistance,
            end: currentDistance + nextDistance,
            price: currentPrice + nextPrice,
          });
          currentDistance = currentDistance + nextDistance;
          currentPrice = currentPrice + nextPrice;
        }
        break;
      case 'addItem':
        const lastIndex = storeDeliveryPriceTier.length - 1;
        storeDeliveryPriceTier.push({
          begin: storeDeliveryPriceTier[lastIndex].end,
          end: storeDeliveryPriceTier[lastIndex].end + 1,
          price: storeDeliveryPriceTier[lastIndex].price + 1,
        });
        break;
      case 'removeItem':
        storeDeliveryPriceTier.splice(event.target.id, 1);
        break;
      case 'priceValue':
        storeDeliveryPriceTier[event.target.id].price = Number(event.target.value) || 0;
        break;
      case 'endValue':
        storeDeliveryPriceTier[event.target.id].end = Number(event.target.value) || 0;
        break;
      default:
      // code block
    }

    let sequence = 0;
    storeDeliveryPriceTier.forEach((item, index) => {
      if (item.begin > sequence) {
        storeDeliveryPriceTier[index].begin = sequence;
      }
      if (item.begin >= item.end) {
        storeDeliveryPriceTier[index].end = item.begin + 1;
      }
      if (storeDeliveryPriceTier[index + 1]) {
        storeDeliveryPriceTier[index + 1].begin = storeDeliveryPriceTier[index].end;
      }
      sequence = item.end + 1;
    });

    if (storeDeliveryPriceTier.length === 0) {
      storeDeliveryPriceTier.push({
        begin: 0,
        end: 5,
        price: 0,
      });
    }

    setFormData({ ...formData, ...formNode.current.getFormData(), ...{ storeDeliveryPriceTier } });
  };

  const handleStoreDiscountQuantityTierChange = (event) => {
    let storeDiscountQuantityTier = JSON.parse(JSON.stringify(formData.storeDiscountQuantityTier));
    console.log(' event.target.name ', event.target.name, event.target.value);
    switch (event.target.name) {
      case 'addItem':
        const lastIndex = storeDiscountQuantityTier.length - 1;
        storeDiscountQuantityTier.push({
          begin: storeDiscountQuantityTier[lastIndex].end,
          end: storeDiscountQuantityTier[lastIndex].end + 1,
          discount: storeDiscountQuantityTier[lastIndex].discount + 1,
        });
        break;
      case 'removeItem':
        storeDiscountQuantityTier.splice(event.target.id, 1);
        break;
      case 'discountValue':
        const discountValue = Number(event.target.value) || 0;
        storeDiscountQuantityTier[event.target.id].discount = discountValue;
        if (discountValue < 1) {
          storeDiscountQuantityTier[event.target.id].error = 'Minimum value is 1';
        } else if (discountValue > 90) {
          storeDiscountQuantityTier[event.target.id].error = 'Maximum value is 90';
        } else {
          if (storeDiscountQuantityTier[event.target.id].error) {
            delete storeDiscountQuantityTier[event.target.id].error;
          }
        }
        break;
      case 'beginValue':
        storeDiscountQuantityTier[event.target.id].begin = Number(event.target.value) || 0;
        break;
      case 'endValue':
        storeDiscountQuantityTier[event.target.id].end = Number(event.target.value) || 0;
        break;
      default:
      // code block
    }

    let sequence = storeDiscountQuantityTier[0]?.begin;
    storeDiscountQuantityTier.forEach((item, index) => {
      if (item.begin > sequence) {
        storeDiscountQuantityTier[index].begin = sequence;
      }
      if (item.begin >= item.end) {
        storeDiscountQuantityTier[index].end = item.begin + 1;
      }
      if (storeDiscountQuantityTier[index + 1]) {
        storeDiscountQuantityTier[index + 1].begin = storeDiscountQuantityTier[index].end + 1;
      }
      sequence = item.end + 1;
    });

    if (storeDiscountQuantityTier.length === 0) {
      storeDiscountQuantityTier.push({
        begin: 10,
        end: 19,
        discount: 1,
      });
    }

    setFormData({ ...formData, ...formNode.current.getFormData(), ...{ storeDiscountQuantityTier } });
  };

  const handleGetStripeLink = async () => {
    dispatch(getStripeAccountLink({ returnUrl: window.location.href }))
      .unwrap()
      .then(({ data }) => {
        const { created, expires_at, object, url } = data.accountLink;
        window.location.replace(url);
      });
  };

  const handleCustomerOption = (event, type) => {
    if (type === 'selfPickupEnable') {
      setSelfPickupEnable(event);
      updateFormData({
        selfPickupEnable: event,
      });
    }
    if (type === 'deliveryEnable') {
      setDeliveryEnable(event);
      updateFormData({
        deliveryEnable: event,
      });
    }

    if (event === false) {
      if (type === 'deliveryEnable' && selfPickupEnable === false) {
        setCustomerOptionWarnig(true);
      } else if (type === 'selfPickupEnable' && deliveryEnable === false) {
        setCustomerOptionWarnig(true);
      }
    } else {
      setCustomerOptionWarnig(false);
    }
  };

  const DeliveryPriceTier = () => {
    return (
      <>
        {deliveryPriceTierEnable ? (
          <div>
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th scope="col" className="bg-gray-50 px-2 py-0 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Begin (KM)
                  </th>
                  <th scope="col" className="bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    End (KM)
                  </th>
                  <th scope="col" className="bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Price (RM)
                  </th>
                  <th scope="col" className="bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
                </tr>
              </thead>
              <tbody className="divide-y">
                {formData?.storeDeliveryPriceTier?.map((item, index) => (
                  <tr key={index}>
                    <td className="px-6 py-0 whitespace-nowrap text-left text-sm">{item.begin}</td>
                    <td className="px-6 py-3 whitespace-nowrap text-left text-sm">
                      <input
                        className={`mt-1 block w-16 shadow-sm sm:text-sm rounded-md disabled:opacity-50 disabled:bg-gray-200s`}
                        type="tel"
                        name="endValue"
                        id={index}
                        defaultValue={item.end}
                        // placeholder={placeholder}
                        // disabled={disabled}
                        onBlur={handleStoreDeliveryPriceTierChange}
                      />
                    </td>
                    <td className="px-6 py-3 whitespace-nowrap text-left text-sm">
                      <input
                        className={`mt-1 block w-16 shadow-sm sm:text-sm rounded-md disabled:opacity-50 disabled:bg-gray-200s`}
                        type="tel"
                        name="priceValue"
                        id={index}
                        defaultValue={item.price}
                        // placeholder={placeholder}
                        // disabled={disabled}
                        min={0}
                        onBlur={handleStoreDeliveryPriceTierChange}
                      />
                    </td>
                    <td className="px-0 py-0 whitespace-nowrap text-left text-sm">
                      <button
                        type="button"
                        onClick={handleStoreDeliveryPriceTierChange}
                        name="removeItem"
                        id={index}
                        // disabled={disabled || loading}
                        className={`disabled:opacity-50 text-sm font-semibold bg-white text-red-600 py-1 px-2 rounded-md hover:bg-gray-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-offset-2 focus-visible:ring-offset-gray-900`}
                      >
                        ❌
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="py-2 flex flex-col bg-white border rounded-md shadow">
              <button
                type="button"
                onClick={handleStoreDeliveryPriceTierChange}
                name="addItem"
                // disabled={disabled || loading}
                className={`disabled:opacity-50 text-sm font-semibold bg-white text-gray-700 py-1 px-2 rounded-md hover:bg-gray-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-offset-2 focus-visible:ring-offset-gray-900`}
              >
                Add new tier
              </button>
            </div>
            <div className="py-4"></div>
            <button
              type="button"
              class="mb-4 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:w-auto sm:text-sm"
              onClick={handleStoreDeliveryPriceTierChange}
              name="autoGenerate"
            >
              Auto Fill Delivery Price Tier
            </button>
            {latestAvailabilityRadiusKm > formData?.storeDeliveryPriceTier[formData?.storeDeliveryPriceTier.length - 1].end && (
              <p id="section-deliveryPriceTier-warning" class="mt-2 text-xs text-red-500 tracking-wide font-medium sm:font-normal">
                Last tier on END(<b>{formData?.storeDeliveryPriceTier[formData?.storeDeliveryPriceTier.length - 1].end}</b> KM) is less than Availability distance (
                <b>{latestAvailabilityRadiusKm}</b> KM). Please update your <b>Delivery price tier</b> to match with Availability distance
              </p>
            )}
          </div>
        ) : (
          <div>
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th scope="col" className="bg-gray-50 px-2 py-0 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Begin (KM)
                  </th>
                  <th scope="col" className="bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    End (KM)
                  </th>
                  <th scope="col" className="bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Price (RM)
                  </th>
                  <th scope="col" className="bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
                </tr>
              </thead>
              <tbody className="divide-y bg-gray-50">
                {formData?.storeDeliveryPriceTier?.map((item, index) => (
                  <tr key={index}>
                    <td className="px-6 py-0 whitespace-nowrap text-left text-sm">{item.begin}</td>
                    <td className="px-6 py-3 whitespace-nowrap text-left text-sm">{item.end}</td>
                    <td className="px-6 py-3 whitespace-nowrap text-left text-sm">{item.price}</td>
                    <td className="px-0 py-0 whitespace-nowrap text-left text-sm"></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </>
    );
  };

  const DiscountQuantityTier = () => {
    return (
      <>
        {discountQuantityTierEnable ? (
          <div>
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th scope="col" className="bg-gray-50 px-2 py-0 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Begin (Qty)
                  </th>
                  <th scope="col" className="bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    End (Qty)
                  </th>
                  <th scope="col" className="bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Discount (%)
                  </th>
                  <th scope="col" className="bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
                </tr>
              </thead>
              <tbody className="divide-y">
                {formData?.storeDiscountQuantityTier?.map((item, index) => (
                  <>
                    <tr key={index}>
                      {index === 0 ? (
                        <td className="px-2 py-3 whitespace-nowrap text-left text-sm">
                          <input
                            className={`mt-1 block w-16 shadow-sm sm:text-sm rounded-md disabled:opacity-50 disabled:bg-gray-200s`}
                            type="tel"
                            name="beginValue"
                            id={index}
                            defaultValue={item.begin}
                            // placeholder={placeholder}
                            // disabled={disabled}
                            onBlur={handleStoreDiscountQuantityTierChange}
                          />
                        </td>
                      ) : (
                        <td className="px-2 py-0 whitespace-nowrap text-left text-sm">{item.begin}</td>
                      )}
                      {index + 1 === formData?.storeDiscountQuantityTier?.length ? (
                        <td className="px-2 py-3 whitespace-nowrap text-left text-sm">to Max</td>
                      ) : (
                        <td className="px-2 py-3 whitespace-nowrap text-left text-sm">
                          <input
                            className={`mt-1 block w-16 shadow-sm sm:text-sm rounded-md disabled:opacity-50 disabled:bg-gray-200s`}
                            type="tel"
                            name="endValue"
                            id={index}
                            defaultValue={item.end}
                            // placeholder={placeholder}
                            // disabled={disabled}
                            onBlur={handleStoreDiscountQuantityTierChange}
                          />
                        </td>
                      )}
                      <td className="px-2 py-3 whitespace-nowrap text-left text-sm">
                        <input
                          className={`mt-1 block w-16 shadow-sm sm:text-sm rounded-md disabled:opacity-50 disabled:bg-gray-200 ${item.error && 'border-red-500'}`}
                          type="tel"
                          name="discountValue"
                          id={index}
                          defaultValue={item.discount}
                          // placeholder={placeholder}
                          // disabled={disabled}
                          min={0}
                          onBlur={handleStoreDiscountQuantityTierChange}
                        />
                        {/* {item.error && <p class="mt-2 text-sm text-red-600 dark:text-red-500">{item.error}</p> } */}
                      </td>
                      <td className="px-0 py-0 whitespace-nowrap text-left text-sm">
                        <button
                          type="button"
                          onClick={handleStoreDiscountQuantityTierChange}
                          name="removeItem"
                          id={index}
                          // disabled={disabled || loading}
                          className={`disabled:opacity-50 text-sm font-semibold bg-white text-red-600 py-1 px-2 rounded-md hover:bg-gray-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-offset-2 focus-visible:ring-offset-gray-900`}
                        >
                          ❌
                        </button>
                      </td>
                    </tr>
                    {item.error && (
                      <tr>
                        <td colspan="3" align="right" class="align-top">
                          <p id="section-discountQuantityTier-warning" class="mt-2 text-sm text-red-600 dark:text-red-500">
                            {item.error}
                          </p>
                        </td>
                      </tr>
                    )}
                  </>
                ))}
              </tbody>
            </table>
            <div className="py-2 flex flex-col bg-white border rounded-md shadow">
              <button
                type="button"
                onClick={handleStoreDiscountQuantityTierChange}
                name="addItem"
                // disabled={disabled || loading}
                className={`disabled:opacity-50 text-sm font-semibold bg-white text-gray-700 py-1 px-2 rounded-md hover:bg-gray-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-offset-2 focus-visible:ring-offset-gray-900`}
              >
                Add new tier
              </button>
            </div>
            <div className="py-4"></div>
            {/* <button
              type="button"
              class="mb-4 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:w-auto sm:text-sm"
              onClick={handleStoreDiscountQuantityTierChange}
              name="autoGenerate"
            >
              Auto Fill Discount Quantity Tier
            </button> */}
            {/* {latestAvailabilityRadiusKm > formData?.storeDiscountQuantityTier[formData?.storeDiscountQuantityTier.length - 1].end && (
              <p id="section-discountQuantityTier-warning" class="mt-2 text-xs text-red-500 tracking-wide font-medium sm:font-normal">
                Last tier on END(<b>{formData?.storeDiscountQuantityTier[formData?.storeDiscountQuantityTier.length - 1].end}</b> KM) is less than Availability distance (
                <b>{latestAvailabilityRadiusKm}</b> KM). Please update your <b>Discount Quantity Tier</b> to match with Availability distance
              </p>
            )} */}
          </div>
        ) : (
          <div>
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th scope="col" className="bg-gray-50 px-2 py-0 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Begin (Qty)
                  </th>
                  <th scope="col" className="bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    End (Qty)
                  </th>
                  <th scope="col" className="bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Discount (%)
                  </th>
                  <th scope="col" className="bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
                </tr>
              </thead>
              <tbody className="divide-y bg-gray-50">
                {formData?.storeDiscountQuantityTier?.map((item, index) => (
                  <tr key={index}>
                    <td className="px-6 py-0 whitespace-nowrap text-left text-sm">{item.begin}</td>
                    {index + 1 === formData?.storeDiscountQuantityTier?.length ? (
                      <td className="px-6 py-3 whitespace-nowrap text-left text-sm">To Max</td>
                    ) : (
                      <td className="px-6 py-3 whitespace-nowrap text-left text-sm">{item.end}</td>
                    )}

                    <td className="px-6 py-3 whitespace-nowrap text-left text-sm">{item.discount}</td>
                    <td className="px-0 py-0 whitespace-nowrap text-left text-sm"></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <h2 className="text-xl sm:text-2xl font-bold text-gray-900">Profile</h2>
      <div className="mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="flex items-center p-6 px-4 sm:px-6">
          {vendorApi.loading || fileApi.loading ? (
            <div className="animate-pulse bg-gray-400 rounded-full h-16 w-16 flex-shrink-0" />
          ) : (
            <Image
              className="rounded-full h-16 w-16 object-cover object-center ring-1 ring-black ring-opacity-10 flex-shrink-0"
              src={profile?.storeProfilePic?.path?.s}
              alt="storeProfilePic-size-S"
            />
          )}
          <div className="flex items-start w-full ml-4">
            <div>
              {vendorApi.loading ? (
                <div className="animate-pulse h-8 w-40 bg-gray-400 rounded" />
              ) : (
                <div className="flex items-center">
                  <h3 className="text-xl leading-6 font-bold text-gray-900">{profile?.storeName}</h3>
                  <p className="ml-3 max-w-2xl text-sm font-medium text-gray-500">
                    <dd className="mt-2 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <span
                        className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-md  
                            ${profile?.status === 'REGISTER' ? 'bg-blue-100 text-blue-600' : ''}
                            ${profile?.status === 'PENDING' ? 'bg-primary-100 text-primary-600' : ''}
                            ${profile?.status === 'ONLINE' ? 'bg-green-100 text-green-600' : ''}
                            ${profile?.status === 'SUSPEND' ? 'bg-red-100 text-red-600' : ''}
                          `}
                      >
                        {profile?.status}
                      </span>
                    </dd>
                  </p>
                </div>
              )}
              {vendorApi.loading ? (
                <div className="mt-1 animate-pulse h-4 w-80 bg-gray-400 rounded"></div>
              ) : (
                <p className="mt-1 max-w-2xl text-xs text-gray-500">
                  ID: <span className="font-mono">{profile?.uid}</span>
                </p>
              )}
            </div>
            <ShareButton vendorPath={profile?.username || profile?.uid} storeName={profile?.storeName} />
          </div>
        </div>
        <div className="border-t border-gray-200">
          <Form onSubmit={handleSubmit} defaultValues={formData} setErrors={formErrors} setRef={formNode} triggerUpdateData={triggerUpdate}>
            <dl className="divide-y">
              <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Service Fee Percentage</dt>
                {vendorApi.loading ? (
                  <div className="animate-pulse h-4 w-auto bg-gray-400 rounded" />
                ) : (
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {profile?.serviceChargePercentage == 0 ? 0 : profile?.serviceChargePercentage || ServiceChargePercentage}%
                  </dd>
                )}
              </div>
              <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Store profile photo</dt>
                {vendorApi.loading ? (
                  <div className="animate-pulse h-4 w-auto bg-gray-400 rounded" />
                ) : (
                  <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <Input.Avatar
                      name="storeProfilePic"
                      apiParams={{ object: 'vendor.storeProfilePic' }}
                      disabled={vendorApi.loading}
                      rules={[{ required: true, message: 'This field is required' }]}
                    />
                  </dd>
                )}
              </div>
              <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Store banner photo</dt>
                {vendorApi.loading ? (
                  <div className="animate-pulse h-4 w-auto bg-gray-400 rounded" />
                ) : (
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <Input.File
                      name="storeBannerPic"
                      apiParams={{ object: 'vendor.storeBannerPic' }}
                      withPhotoCropper={true}
                      aspectRatio={4 / 1}
                      imageDimension="w-[600px] h-[150px]"
                      disabled={vendorApi.loading}
                      accept=".jpeg,.jpg,.png,.webp,.gif,.svg"
                      rules={[{ required: true, message: 'This field is required' }]}
                    />
                  </dd>
                )}
              </div>
              <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Store name</dt>
                {vendorApi.loading ? (
                  <div className="animate-pulse h-4 w-auto bg-gray-400 rounded" />
                ) : (
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <Input.Text name="storeName" placeholder="Your store name" disabled={vendorApi.loading} rules={[{ required: true, message: 'This field is required' }]} />
                  </dd>
                )}
              </div>
              <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Username</dt>
                {vendorApi.loading ? (
                  <div className="animate-pulse h-4 w-auto bg-gray-400 rounded" />
                ) : (
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {profile?.username ? profile?.username : <p className="text-gray-400">Username is not define yet. Please contact our admin to add username</p>}
                  </dd>
                )}
              </div>
              <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Store description</dt>
                {vendorApi.loading ? (
                  <div className="animate-pulse h-4 w-auto bg-gray-400 rounded" />
                ) : (
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <Input.Textarea
                      name="storeDescription"
                      placeholder="Your store description"
                      disabled={vendorApi.loading}
                      rules={[{ required: true, message: 'This field is required' }]}
                    />
                  </dd>
                )}
              </div>
              <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Store address</dt>
                {vendorApi.loading ? (
                  <div className="animate-pulse h-4 w-auto bg-gray-400 rounded" />
                ) : (
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <Input.GoogleMap setRef={mapRef} className="sr-only" name="storeLocationAddressHiddenInput" fieldType="address" />
                    <div className="mt-2 md:mt-0 md:col-span-2 grid grid-cols-1 md:grid-cols-6 gap-6">
                      <div className="col-span-1 md:col-span-3">
                        <Input.Text
                          name="storeAddress1"
                          placeholder="Address Line 1"
                          disabled={vendorApi.loading}
                          rules={[{ required: true, message: 'This field is required' }]}
                        />
                      </div>
                      <div className="col-span-1 md:col-span-3">
                        <Input.Text name="storeAddress2" placeholder="Address Line 2" disabled={vendorApi.loading} />
                      </div>
                      <div className="col-span-1 md:col-span-2">
                        <Input.Text name="storeCity" placeholder="City" disabled={vendorApi.loading} rules={[{ required: true, message: 'This field is required' }]} />
                      </div>
                      <div className="col-span-1 md:col-span-2">
                        <Input.Select
                          name="storeState"
                          placeholder="Select a state"
                          options={[
                            'Perlis',
                            'Kedah',
                            'Perak',
                            'Selangor',
                            'Kuala Lumpur',
                            'Negeri Sembilan',
                            'Melaka',
                            'Johor',
                            'Kelantan',
                            'Terengganu',
                            'Pahang',
                            'Putrajaya',
                            'Labuan',
                            'Sabah',
                            'Sarawak',
                          ]}
                          disabled={vendorApi.loading}
                          rules={[{ required: true, message: 'This field is required' }]}
                        />
                      </div>
                      <div className="col-span-1 md:col-span-2">
                        <Input.Text name="storePostalCode" placeholder="Postal code" disabled={vendorApi.loading} rules={[{ required: true, message: 'This field is required' }]} />
                      </div>
                    </div>
                  </dd>
                )}
              </div>
              <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Store location</dt>
                {vendorApi.loading ? (
                  <div className="animate-pulse h-4 w-auto bg-gray-400 rounded" />
                ) : (
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <button
                      type="button"
                      onClick={() => handleStoreAddress()}
                      className="mb-4 font-medium text-primary-500 hover:text-primary-600 border-primary-600 border-b border-dotted"
                    >
                      Get store address coordinate
                    </button>
                    <Input.GoogleMap
                      name="storeLocationLatLong"
                      hint="Insert the latitude and longitude"
                      disabled={vendorApi.loading}
                      hideCurrentLocationButton
                      rules={[{ required: true, message: 'This field is required' }]}
                    />
                  </dd>
                )}
              </div>
              {/* <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Total load capacity</dt>
                {vendorApi.loading ? (
                  <div className="animate-pulse h-4 w-auto bg-gray-400 rounded" />
                ) : (
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <Input.Number
                      type="Integer"
                      name="totalLoadCapacity"
                      placeholder="e.g 100"
                      disabled={vendorApi.loading}
                      rules={[{ required: true, message: 'This field is required' }]}
                    />
                  </dd>
                )}
              </div> */}
              <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Availability distance (KM)</dt>
                {vendorApi.loading ? (
                  <div className="animate-pulse h-4 w-auto bg-gray-400 rounded" />
                ) : (
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <Input.Number
                      type="Integer"
                      name="availabilityRadiusKm"
                      placeholder="e.g 10"
                      disabled={vendorApi.loading}
                      retrieveValue={(e) => {
                        setLatestAvailabilityRadiusKm(e.target.value);
                      }}
                      rules={[{ required: true, message: 'This field is required' }]}
                    />
                  </dd>
                )}
              </div>
              <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Customer option</dt>
                {vendorApi.loading ? (
                  <div className="animate-pulse h-4 w-auto bg-gray-400 rounded" />
                ) : (
                  <>
                    <div className="mt-2 md:mt-0 grid grid-cols-1 gap-6">
                      <div className="col-span-1 md:col-span-1">
                        <Input.Switch
                          name="selfPickupEnable"
                          label="Self Pickup"
                          value={selfPickupEnable}
                          retrieveValue={(e) => {
                            handleCustomerOption(e, 'selfPickupEnable');
                          }}
                        />
                      </div>
                      <div className="col-span-1 md:col-span-1">
                        <Input.Switch
                          name="deliveryEnable"
                          label="Delivery"
                          value={deliveryEnable}
                          retrieveValue={(e) => {
                            handleCustomerOption(e, 'deliveryEnable');
                          }}
                        />
                      </div>
                      {customerOptionWarnig && (
                        <p id="section-customerOption-warning" className="text-sm font-normal text-red-500">
                          *Cannot disabled both option <b>Self Pickup</b> and <b>Delivery</b>{' '}
                        </p>
                      )}
                    </div>
                    {/* <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <Input.Switch 
                      name="selfPickupEnable"
                      label="Self Pickup"
                    />
                  </dd>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <Input.Switch 
                      name="deliveryEnable"
                      label="Delivery"
                    />
                  </dd> */}
                  </>
                )}
              </div>
              <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Delivery price tier</dt>
                {vendorApi.loading ? (
                  <div className="animate-pulse h-4 w-auto bg-gray-400 rounded" />
                ) : (
                  <dd>
                    <div className="mt-2 md:mt-0 grid grid-cols-1 gap-6">
                      {!deliveryPriceTierEnable && (
                        <div className=" py-4">
                          <Tooltip from="up" bgColor="bg-amber-500/95 text-white" chevronColor="text-amber-500">
                            <b> Enable this to set Delivery Price Tier by distance in KM </b>
                          </Tooltip>
                        </div>
                      )}

                      <div className="col-span-1 md:col-span-1">
                        <Input.Switch
                          name="storeDeliveryPriceEnable"
                          label="Enable Delivery Price Tier"
                          retrieveValue={(e) => {
                            setDeliveryPriceTierEnable(e);
                            updateFormData({
                              storeDeliveryPriceEnable: e,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <DeliveryPriceTier status={deliveryPriceTierEnable} />
                  </dd>
                )}
              </div>
              <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Discount Quantity Tier</dt>
                {vendorApi.loading ? (
                  <div className="animate-pulse h-4 w-auto bg-gray-400 rounded" />
                ) : (
                  <dd>
                    <div className="mt-2 md:mt-0 grid grid-cols-1 gap-6">
                      {!discountQuantityTierEnable && (
                        <div className=" py-4">
                          <Tooltip from="up" bgColor="bg-amber-500/95 text-white" chevronColor="text-amber-500">
                            <b> Enable this to set Discount Quantity Tier by price in RM </b>
                          </Tooltip>
                        </div>
                      )}

                      <div className="col-span-1 md:col-span-1">
                        <Input.Switch
                          name="storeDiscountQuantityEnable"
                          label="Enable Discount Quantity Tier"
                          retrieveValue={(e) => {
                            setDiscountQuantityTierEnable(e);
                            updateFormData({
                              storeDiscountQuantityEnable: e,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <DiscountQuantityTier status={discountQuantityTierEnable} />
                  </dd>
                )}
              </div>
              <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Operation Hours</dt>
                {vendorApi.loading ? (
                  <div className="animate-pulse h-4 w-auto bg-gray-400 rounded" />
                ) : (
                  <dd className="mt-4 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <div className="w-full px-2 sm:px-0">
                      <div className="flex p-1 space-x-1 bg-gray-200/40 rounded-xl">
                        {days.map((day) => (
                          <button
                            type="button"
                            key={day.value}
                            onClick={() => setSelectedDay(day.value)}
                            className={classNames(
                              'w-full py-1.5 text-sm leading-5 font-medium text-primary-500 rounded-lg capitalize',
                              'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-primary-400 ring-white ring-opacity-60',
                              selectedDay === day.value ? 'bg-white shadow' : 'text-gray-500 hover:text-primary-500',
                            )}
                          >
                            {day.name}
                          </button>
                        ))}
                      </div>
                      <div className="mt-4">
                        {days.map((day, idx) => (
                          <div
                            key={idx}
                            className={classNames('bg-white rounded-xl p-3 ring-1 ring-black ring-opacity-5 focus:outline-none shadow', selectedDay !== day.value ? 'hidden' : '')}
                          >
                            <Input.PresetTimePicker
                              name={`storeOperationSchedules.${day.value}`}
                              times={['08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00']}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </dd>
                )}
              </div>
              <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Company name</dt>
                {vendorApi.loading ? (
                  <div className="animate-pulse h-4 w-auto bg-gray-400 rounded" />
                ) : (
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <Input.Text name="companyName" placeholder="Your company name" disabled={vendorApi.loading} />
                  </dd>
                )}
              </div>
              <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Company registration number</dt>
                {vendorApi.loading ? (
                  <div className="animate-pulse h-4 w-auto bg-gray-400 rounded" />
                ) : (
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <Input.Text name="companyNumber" placeholder="Your company registration number" disabled={vendorApi.loading} />
                  </dd>
                )}
              </div>
              <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Company SSM certificate</dt>
                {vendorApi.loading ? (
                  <div className="animate-pulse h-4 w-auto bg-gray-400 rounded" />
                ) : (
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <Input.File name="companySSMCert" apiParams={{ object: 'vendor.companySSMCert' }} disabled={vendorApi.loading} maxFiles={2} accept=".pdf,.jpeg,.jpg,.png" />
                  </dd>
                )}
              </div>
              <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Admin name</dt>
                {vendorApi.loading ? (
                  <div className="animate-pulse h-4 w-auto bg-gray-400 rounded" />
                ) : (
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <Input.Text name="adminName" placeholder="Your admin name" disabled={vendorApi.loading} rules={[{ required: true, message: 'This field is required' }]} />
                  </dd>
                )}
              </div>
              <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Admin contact number</dt>
                {vendorApi.loading ? (
                  <div className="animate-pulse h-4 w-auto bg-gray-400 rounded" />
                ) : (
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <Input.Phone
                      name="adminContactNumber"
                      placeholder="e.g 60123456789"
                      disabled={vendorApi.loading}
                      rules={[{ required: true, message: 'This field is required' }]}
                    />
                  </dd>
                )}
              </div>
              <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Stripe account</dt>
                {vendorApi.loading ? (
                  <div className="animate-pulse h-4 w-auto bg-gray-400 rounded" />
                ) : (
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <button
                      type="button"
                      onClick={handleGetStripeLink}
                      className="mb-4 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:w-auto sm:text-sm"
                    >
                      Update Stripe account
                    </button>
                    <div className="divide-y">
                      <div className="pb-2 flex justify-between">
                        <p className="text-sm font-medium text-gray-500">ID</p>
                        <p className="text-sm font-medium text-gray-900">{formData?.stripeAccountId}</p>
                      </div>
                      <div className="py-2 w-full">
                        <p className="text-sm font-medium text-gray-500">Business profile</p>
                        <table className="mt-2 w-full">
                          <tbody>
                            <tr>
                              <td>MCC</td>
                              <td className="text-sm font-medium text-gray-900 text-right">{formData?.stripeAccount?.business_profile?.mcc || 'N/A'}</td>
                            </tr>
                            <tr>
                              <td>Name</td>
                              <td className="text-sm font-medium text-gray-900 text-right">{formData?.stripeAccount?.business_profile?.name || 'N/A'}</td>
                            </tr>
                            <tr>
                              <td>Support Address</td>
                              <td className="text-sm font-medium text-gray-900 text-right">{formData?.stripeAccount?.business_profile?.support_address || 'N/A'}</td>
                            </tr>
                            <tr>
                              <td>Support Email</td>
                              <td className="text-sm font-medium text-gray-900 text-right">{formData?.stripeAccount?.business_profile?.support_email || 'N/A'}</td>
                            </tr>
                            <tr>
                              <td>Support Phone</td>
                              <td className="text-sm font-medium text-gray-900 text-right">{formData?.stripeAccount?.business_profile?.support_phone || 'N/A'}</td>
                            </tr>
                            <tr>
                              <td>Support URL</td>
                              <td className="text-sm font-medium text-gray-900 text-right">{formData?.stripeAccount?.business_profile?.support_url || 'N/A'}</td>
                            </tr>
                            <tr>
                              <td>URL</td>
                              <td className="text-sm font-medium text-gray-900 text-right">{formData?.stripeAccount?.business_profile?.url || 'N/A'}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="py-2 flex justify-between">
                        <p className="text-sm font-medium text-gray-500">Account completed?</p>
                        <p className="text-sm font-medium text-gray-900">{formData?.stripeAccount?.payouts_enabled ? 'Yes' : 'No'}</p>
                      </div>
                    </div>
                  </dd>
                )}
              </div>
            </dl>

            <div className="mt-6 px-4 py-3 bg-gray-50 text-right sm:px-6 border-t">
              <button
                type="submit"
                onClick={() => {
                  setTimeout(() => {
                    const element = document.getElementById('rule-error');
                    element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
                  }, 100);
                }}
                disabled={vendorApi.loading || fileApi.loading}
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-primary-100 bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
              >
                {vendorApi.loading || fileApi.loading ? (
                  <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                ) : (
                  'Update'
                )}
              </button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default StoreProfile;
