import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useHistory } from 'react-router-dom';
import _ from 'lodash';
import { ModalDiscard, ModalUsage } from '../../../components/Modal';

import { Form, Input } from '../../../components/Form';
import Image from '../../../components/Image';

import { showToast } from '../../../store/slices/components.slice';
import { getProduct, updateProduct, deleteProduct, getVendor } from '../../../store/slices/vendor.slice';
import { ServiceChargePercentage } from '../../../utils';

const StoreProductsUpdate = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const formNode = useRef();
  const { id } = useParams();

  const [formData, setFormData] = useState(null);
  const [formErrors, setFormErrors] = useState([]);
  const { vendorApi, fileApi } = useSelector((state) => state);
  const [modalOpen, setModulOpen] = useState(false);
  const [newPrice, setNewPrice] = useState(0);
  const [usage, setUsage] = useState(1);
  const [modalVendorOpen, setModulVendorOpen] = useState(false);
  // const [totalLoad, setTotalLoad] = useState(null);
  // const [loadUsage, setLoadUsage] = useState(formData?.loadUsage);
  const [currentServiceChargePercentage, setCurrentServiceChargePercentage] = useState(ServiceChargePercentage);

  useEffect(() => {
    handleGetProduct(id);
    handleGetVendor();
  }, []);

  const handleGetVendor = () => {
    dispatch(getVendor())
      .unwrap()
      .then((result) => {
        const { data } = result;
        // setTotalLoad(data?.totalLoadCapacity);
        if (data?.serviceChargePercentage || data?.serviceChargePercentage == 0) {
          setCurrentServiceChargePercentage(data?.serviceChargePercentage);
        }
      })
      .catch(({ message }) => {
        dispatch(showToast({ show: true, type: 'error', message: message }));
      });
  };

  const handleGetProduct = (id) => {
    dispatch(getProduct(id))
      .unwrap()
      .then((result) => {
        const { data } = result;
        setFormData(data);
        // parseInt(setLoadUsage(data?.loadUsage));
        if (data.newData == undefined) {
          setNewPrice(0);
        } else {
          setNewPrice(data.newData.price);
        }
      })
      .catch(({ message }) => {
        dispatch(showToast({ show: true, type: 'error', message: message }));
      });
  };

  const handleSubmit = (data) => {
    if (data.name) {
      delete data.name;
    }
    if (data.price) {
      delete data.price;
    }
    if (data.description) {
      delete data.description;
    }

    if (data.newData) {
      // Remove all empty values (null, undefined or empty string) in newData
      data.newData = Object.entries(data.newData).reduce((a, [k, v]) => (!v ? a : ((a[k] = v), a)), {});
      if (_.isEmpty(data.newData)) data.newData = undefined;
    }

    // dispatch(updateProduct({ id: id, data: { ...data, loadUsage: parseInt(loadUsage) } }))
    dispatch(updateProduct({ id: id, data: { ...data } }))
      .unwrap()
      .then((result) => {
        dispatch(showToast({ show: true, type: 'success', message: `Your product has been successfully updated!` }));
        history.push('/store/products');
      })
      .catch(({ message, validate }) => {
        setFormData(formData);
        dispatch(showToast({ show: true, type: 'error', message: message, errors: validate?.details }));
        setFormErrors(validate?.details || message);
      });
  };

  const handleDelete = (data) => {
    dispatch(deleteProduct(id))
      .unwrap()
      .then((result) => {
        dispatch(showToast({ show: true, type: 'success', message: `Your product has been successfully discard!` }));
        history.push('/store/products');
      })
      .catch(({ message, validate }) => {
        setFormData(data);
        dispatch(showToast({ show: true, type: 'error', message: message, errors: validate?.details }));
        setFormErrors(validate?.details);
        setTimeout(() => {
          const element = document.getElementById('rule-error');
          element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }, 100);
      });
  };

  function handleNewPrice(e) {
    setNewPrice(e.target.value);
  }

  function handleUsage(e) {
    setUsage(e.target.value);
  }

  // const updateState = () => {
  //   let data = 0;
  //   setModulVendorOpen(false);
  //   if (usage == 0 || usage > totalLoad) {
  //     data = totalLoad;
  //   } else {
  //     data = usage;
  //   }
  //   parseInt(setLoadUsage((totalLoad / data).toFixed(0)));
  // };

  return (
    <>
      <ul className="flex items-center space-x-2">
        <li className="text-gray-500 text-sm font-medium">
          <Link to="/store/products">Products</Link>
        </li>
        <li>
          <svg className="h-5 w-5 text-gray-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path
              fillRule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clipRule="evenodd"
            ></path>
          </svg>
        </li>
        <li className="text-gray-900 text-sm font-medium">{formData?.newData?.name || formData?.name || id}</li>
      </ul>
      <div className="mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
        <div>
          <div className="md:grid md:grid-cols-3 md:gap-6 p-4 md:p-6">
            <div className="md:col-span-1">
              <div>
                {vendorApi.loading ? (
                  <div className="animate-pulse h-8 w-40 bg-gray-400 rounded" />
                ) : (
                  <div className="flex items-center">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">{formData?.newData?.name || formData?.name || id}</h3>
                  </div>
                )}
                {vendorApi.loading ? (
                  <div className="mt-1 animate-pulse h-4 w-80 bg-gray-400 rounded"></div>
                ) : (
                  <p className="mt-1 max-w-2xl text-xs text-gray-500">
                    ID: <span className="font-mono">{id}</span>
                  </p>
                )}
              </div>
            </div>
            <div className="mt-4 md:mt-0 md:col-span-2 text-right sm:px-6">
              <Form onSubmit={() => setModulOpen(true)}>
                {!formData?.isApproval ? (
                  <button
                    type="delete"
                    disabled={vendorApi.loading || fileApi.loading}
                    className="disabled:opacity-50 disabled:cursor-not-allowed inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-primary-100 bg-red-600 hover:bg-red-700"
                  >
                    {vendorApi.loading || fileApi.loading ? (
                      <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    ) : (
                      'Discard'
                    )}
                  </button>
                ) : null}
              </Form>
              <ModalDiscard
                show={modalOpen}
                title="Alert!"
                description="Are you sure want to discard this product?"
                // hide={() => this.setState({ showModalForgotPassword: false })}
                loading={vendorApi.loading}
                closeable={false}
                confirmText="Continue Discard"
                cancelText="Cancel"
                confirmAction={handleDelete}
                defaultValues={formData}
                setRef={formNode}
                setErrors={formErrors}
                closeAction={() => setModulOpen(false)}
              >
                <div className="w-full">
                  <div className="px-4 py-5 space-y-6 sm:p-6">
                    <p>This action will be removing this product</p>
                  </div>
                </div>
              </ModalDiscard>
            </div>
          </div>
        </div>
        <div className="border-t border-gray-200">
          <div>
            <Form onSubmit={handleSubmit} defaultValues={formData} setRef={formNode} setErrors={formErrors}>
              <div>
                {formData?.isApproval
                  ? formData?.newData && (
                      <div>
                        <div className="md:grid md:grid-cols-3 md:gap-6 p-4 md:p-6">
                          <div className="md:col-span-1">
                            <div>
                              <h3 className="text-base font-medium leading-6 mt-1 px-2 inline-flex  rounded-md bg-primary-100 text-primary-600 uppercase">Awaiting Verification</h3>
                            </div>
                          </div>
                          <div className="mt-4 md:mt-0 md:col-span-2">
                            <p className="mt-1 text-sm text-red-600">This product is under review and will be verified by the operation team within 1 to 7 working days</p>
                          </div>
                        </div>
                        <div className="block" aria-hidden="true">
                          <div className="border-t border-gray-200"></div>
                        </div>
                      </div>
                    )
                  : formData?.newData && (
                      <div>
                        <div className="md:grid md:grid-cols-3 md:gap-6 p-4 md:p-6">
                          <div className="md:col-span-1">
                            <div>
                              <h3 className="text-base font-medium leading-6 mt-1 px-2 inline-flex  rounded-md bg-red-100 text-red-600 uppercase">Awaiting Approval</h3>
                            </div>
                          </div>
                          <div className="mt-4 md:mt-0 md:col-span-2">
                            <p className="mt-1 text-sm text-red-600">This product is under review and will be approved by the operation team within 1 to 7 working days</p>
                          </div>
                        </div>
                        <div className="block" aria-hidden="true">
                          <div className="border-t border-gray-200"></div>
                        </div>
                      </div>
                    )}
                {formData?.isApproval ? (
                  <>
                    <div>
                      <div className="grid grid-cols-3 gap-6 p-4 md:p-6">
                        <div className="col-span-2 md:col-span-1">
                          <div>
                            <h3 className="text-base font-medium leading-6 text-gray-900">Availability</h3>
                            <p className="mt-1 text-sm text-gray-600">Is this product available?</p>
                          </div>
                        </div>
                        <div className="ml-auto md:ml-0 col-span-1 md:col-span-2">
                          <Input.Switch name="isAvailable" />
                        </div>
                      </div>
                    </div>

                    <div className="block" aria-hidden="true">
                      <div className="border-t border-gray-200"></div>
                    </div>

                    <div>
                      <div className="grid grid-cols-3 gap-6 p-4 md:p-6">
                        <div className="col-span-2 md:col-span-1">
                          <div>
                            <h3 className="text-base font-medium leading-6 text-gray-900">Visibility</h3>
                            <p className="mt-1 text-sm text-gray-600">Make this product visible to public?</p>
                          </div>
                        </div>
                        <div className="ml-auto md:ml-0 col-span-1 md:col-span-2">
                          <Input.Switch name="isVisible" />
                        </div>
                      </div>
                    </div>

                    <div className="block" aria-hidden="true">
                      <div className="border-t border-gray-200"></div>
                    </div>
                  </>
                ) : null}

                <div>
                  <div className="md:grid md:grid-cols-3 md:gap-6 p-4 md:p-6">
                    <div className="md:col-span-1">
                      <div>
                        <h3 className="text-base font-medium leading-6 text-gray-900">
                          {!formData?.picture && <span className="text-red-500 mr-1">*</span>}
                          Product photo
                        </h3>
                        <p className="mt-1 text-sm text-gray-600">Upload your product photo</p>
                      </div>
                    </div>
                    {formData?.picture ? (
                      <div className="mt-4 md:mt-0 md:col-span-2">
                        <fieldset className="border border-solid border-gray-300 rounded-lg p-4">
                          <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6 sm:col-span-3">
                              <span className="font-medium text-sm">Current photo</span>
                              <div className="relative rounded-md ring-1 ring-black ring-opacity-5 overflow-hidden w-full sm:w-[300px] h-[300px]">
                                <Image src={formData?.picture?.path?.m} alt="img-preview-current" className="absolute h-full w-full object-cover object-center" />
                              </div>
                            </div>
                            <div className="col-span-6 sm:col-span-3">
                              <Input.File
                                name="newData.picture"
                                label="New photo"
                                apiParams={{ object: 'product.picture', productUid: formData?.uid }}
                                withPhotoCropper={true}
                                disabled={vendorApi.loading}
                                accept=".jpeg,.jpg,.png,.webp,.gif,.svg"
                                imageDimension="w-full sm:w-[300px] h-[300px]"
                                imageSize="m"
                              />
                            </div>
                          </div>
                        </fieldset>
                      </div>
                    ) : (
                      <Input.File
                        name="newData.picture"
                        apiParams={{ object: 'product.picture', productUid: formData?.uid }}
                        withPhotoCropper={true}
                        disabled={vendorApi.loading}
                        accept=".jpeg,.jpg,.png,.webp,.gif,.svg"
                        imageDimension="w-full sm:w-[300px] h-[300px]"
                        imageSize="m"
                        rules={[{ required: true, message: 'This field is required' }]}
                      />
                    )}
                  </div>
                </div>

                <div className="block" aria-hidden="true">
                  <div className="border-t border-gray-200"></div>
                </div>

                <div>
                  <div className="md:grid md:grid-cols-3 md:gap-6 p-4 md:p-6">
                    <div className="md:col-span-1">
                      <div>
                        <h3 className="text-base font-medium leading-6 text-gray-900">
                          {!formData?.name && <span className="text-red-500 mr-1">*</span>}
                          Product name
                        </h3>
                        <p className="mt-1 text-sm text-gray-600">What is your product name?</p>
                      </div>
                    </div>
                    {formData?.name ? (
                      <div className="mt-4 md:mt-0 md:col-span-2">
                        <fieldset className="border border-solid border-gray-300 rounded-lg p-4">
                          <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6 sm:col-span-3">
                              <Input.Text name="name" label="Current product name" placeholder="Product name" disabled={true} />
                            </div>
                            <div className="col-span-6 sm:col-span-3">
                              <Input.Text name="newData.name" label="New product name" placeholder="Product name" disabled={vendorApi.loading} />
                            </div>
                          </div>
                        </fieldset>
                      </div>
                    ) : (
                      <div className="mt-2 md:mt-0 md:col-span-2">
                        <Input.Text name="newData.name" placeholder="Product name" disabled={vendorApi.loading} rules={[{ required: true, message: 'This field is required' }]} />
                      </div>
                    )}
                  </div>
                </div>

                <div className="block" aria-hidden="true">
                  <div className="border-t border-gray-200"></div>
                </div>

                <div>
                  <div className="md:grid md:grid-cols-3 md:gap-6 p-4 md:p-6">
                    <div className="md:col-span-1">
                      <div>
                        <h3 className="text-base font-medium leading-6 text-gray-900">
                          {!formData?.price && <span className="text-red-500 mr-1">*</span>}
                          Product price
                        </h3>
                        <p className="mt-1 text-sm text-gray-600">What is your product price?</p>
                      </div>
                    </div>
                    {formData?.price ? (
                      <div className="mt-4 md:mt-0 md:col-span-2">
                        <fieldset className="border border-solid border-gray-300 rounded-lg p-4">
                          <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6 sm:col-span-3">
                              <Input.Number type="Float" name="price" label="Current product price" placeholder="Your product price" disabled={true} />
                              <p className="mt-2 text-xs text-gray-500">
                                Service Fee {currentServiceChargePercentage}%: RM {(formData?.price * (currentServiceChargePercentage / 100)).toFixed(2)}{' '}
                              </p>
                              <p className="mt-2 text-xs text-gray-500">Net Price: RM {(formData?.price - formData?.price * (currentServiceChargePercentage / 100)).toFixed(2)}</p>
                            </div>
                            <div className="col-span-6 sm:col-span-3">
                              <Input.Number
                                type="Float"
                                name="newData.price"
                                label="New product price"
                                placeholder="Your product price"
                                retrieveValue={handleNewPrice}
                                disabled={vendorApi.loading}
                              />
                              <p className="mt-2 text-xs text-gray-500">
                                Service Fee {currentServiceChargePercentage}%: RM {(newPrice * (currentServiceChargePercentage / 100)).toFixed(2)}
                              </p>
                              <p className="mt-2 text-xs text-gray-500">Net Price: RM {(newPrice - newPrice * (currentServiceChargePercentage / 100)).toFixed(2)}</p>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                    ) : (
                      <div className="mt-2 md:mt-0 md:col-span-2">
                        <Input.Number
                          type="Float"
                          name="newData.price"
                          placeholder="Your product price"
                          disabled={vendorApi.loading}
                          rules={[{ required: true, message: 'This field is required' }]}
                        />
                      </div>
                    )}
                  </div>
                </div>

                <div className="block" aria-hidden="true">
                  <div className="border-t border-gray-200"></div>
                </div>

                <div>
                  <div className="md:grid md:grid-cols-3 md:gap-6 p-4 md:p-6">
                    <div className="md:col-span-1">
                      <div>
                        <h3 className="text-base font-medium leading-6 text-gray-900">
                          {!formData?.description && <span className="text-red-500 mr-1">*</span>}
                          Product description
                        </h3>
                        <p className="mt-1 text-sm text-gray-600">Briefly describe your product</p>
                      </div>
                    </div>
                    {formData?.description ? (
                      <div className="mt-4 md:mt-0 md:col-span-2">
                        <fieldset className="border border-solid border-gray-300 rounded-lg p-4">
                          <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6 sm:col-span-3">
                              <Input.Textarea name="description" label="Current product description" placeholder="Product description" disabled={true} />
                            </div>
                            <div className="col-span-6 sm:col-span-3">
                              <Input.Textarea name="newData.description" label="New product description" placeholder="Product description" disabled={vendorApi.loading} />
                            </div>
                          </div>
                        </fieldset>
                      </div>
                    ) : (
                      <div className="mt-2 md:mt-0 md:col-span-2">
                        <Input.Textarea
                          name="newData.description"
                          placeholder="Product description"
                          disabled={vendorApi.loading}
                          rules={[{ required: true, message: 'This field is required' }]}
                        />
                      </div>
                    )}
                  </div>
                </div>

                {/* <div className="block" aria-hidden="true">
                  <div className="border-t border-gray-200"></div>
                </div>

                <div>
                  <div className="md:grid md:grid-cols-3 md:gap-6 p-4 md:p-6">
                    <div className="md:col-span-1">
                      <div>
                        <h3 className="text-base font-medium leading-6 text-gray-900">Lead time</h3>
                        <p className="mt-1 text-sm text-gray-600">Number of day to prepare the product.</p>
                      </div>
                    </div>
                    <div className="mt-4 md:mt-0 md:col-span-2">
                      <Input.Number type="Integer" name="leadTime" placeholder="Your product lead time" hint="Minimum 1 day" disabled={vendorApi.loading} />
                    </div>
                  </div>
                </div> */}

                {/* <div className="block" aria-hidden="true">
                  <div className="border-t border-gray-200"></div>
                </div>

                <div>
                  <div className="md:grid md:grid-cols-3 md:gap-6 p-4 md:p-6">
                    <div className="md:col-span-1">
                      <div>
                        <h3 className="text-base font-medium leading-6 text-gray-900">Load usage</h3>
                        <p className="mt-1 text-sm text-gray-600">
                          Number of load is consumed for this product based on your store <span className="font-semibold">Total Load Capacity</span>
                        </p>
                      </div>
                    </div>
                    <div className="mt-4 md:mt-0 md:col-span-2">
                      <Input.Number
                        type="Integer"
                        name="loadUsage"
                        placeholder="Your product load usage"
                        retrieveValue={(e) => {
                          setLoadUsage(e.target.value);
                        }}
                        value={loadUsage}
                        disabled={vendorApi.loading}
                      />
                      <Link onClick={() => setModulVendorOpen(true)} className=" text-amber-500 hover:text-amber-600">
                        Suggestion
                      </Link>
                    </div>
                  </div>
                </div> */}
              </div>

              <div className="mt-6 px-4 py-3 bg-gray-50 text-right sm:px-6 border-t">
                <button
                  type="submit"
                  onClick={() => {
                    setTimeout(() => {
                      const element = document.getElementById('rule-error');
                      element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    }, 100);
                  }}
                  disabled={vendorApi.loading || fileApi.loading}
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-primary-100 bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                >
                  {vendorApi.loading || fileApi.loading ? (
                    <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : (
                    'Update'
                  )}
                </button>
              </div>
            </Form>
            {/* <ModalUsage
              show={modalVendorOpen}
              title="Load Usage Quantity Suggestion"
              confirmAction={updateState}
              // hide={() => this.setState({ showModalForgotPassword: false })}
              closeable={false}
              confirmText="Continue"
              cancelText="back"
              closeAction={() => setModulVendorOpen(false)}
            >
              <Form setRef={formNode} setErrors={formErrors}>
                <div className="w-full">
                  <div className="grid grid-cols-4 gap-4 sm:gap-4 p-6">
                    <div className="col-span-4 sm:col-span-4">
                      <h3 className="text-base font-medium leading-6 text-gray-900">Total quantity of product can create in 1 day:</h3>
                      <p className="mt-1 text-sm text-gray-600">(How much of this product you can create in 1 day?)</p>
                    </div>
                    <div className="col-span-4 sm:col-span-4">
                      <div className="mt-4 md:mt-0 md:col-span-2">
                        <Input.Number
                          type="Integer"
                          name="quantityProduct"
                          placeholder="Your product quantity"
                          retrieveValue={handleUsage}
                          rules={[{ required: true, message: 'This field is required' }]}
                        />
                        {usage > totalLoad && <b className="text-xs text-red-500">Total quantity product cannot more than total load capacity of your store.</b>}
                        {usage < 1 && <b className="text-xs text-red-500">Minimum quantity product is 1.</b>}
                        <p className="mt-2 text-xs text-gray-500">
                          Total load capacity of your store : <b>{totalLoad}</b>
                        </p>
                        <p className="mt-2 text-xs text-gray-500">
                          Your expected load usage : {(usage > totalLoad && <b> 1</b>) || (usage < 1 && <b> 1</b>) || <b> {(totalLoad / usage)?.toFixed(0)}</b>}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </ModalUsage> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default StoreProductsUpdate;
